const prebivaliste_list = [
  { id: 1, name: 'Ada' },
  { id: 2, name: 'Aleksandrovac' },
  { id: 3, name: 'Aleksinac' },
  { id: 4, name: 'Alibunar' },
  { id: 5, name: 'Apatin' },
  { id: 6, name: 'Aranđelovac' },
  { id: 7, name: 'Arilje' },
  { id: 8, name: 'Babušnica' },
  { id: 9, name: 'Bač' },
  { id: 10, name: 'Bačka Palanka' },
  { id: 11, name: 'Bačka Topola' },
  { id: 12, name: 'Bački Petrovac' },
  { id: 13, name: 'Bajina Bašta' },
  { id: 14, name: 'Batočina' },
  { id: 15, name: 'Bečej' },
  { id: 16, name: 'Bela Crkva' },
  { id: 17, name: 'Bela Palanka' },
  { id: 18, name: 'Beočin' },
  { id: 19, name: 'Beograd-Barajevo' },
  { id: 20, name: 'Beograd-Čukarica' },
  { id: 21, name: 'Beograd-Grocka' },
  { id: 22, name: 'Beograd-Lazarevac' },
  { id: 23, name: 'Beograd-Mladenovac' },
  { id: 24, name: 'Beograd-Novi Beograd' },
  { id: 25, name: 'Beograd-Obrenovac' },
  { id: 26, name: 'Beograd-Palilula' },
  { id: 27, name: 'Beograd-Rakovica' },
  { id: 28, name: 'Beograd-Savski Venac' },
  { id: 29, name: 'Beograd-Sopot' },
  { id: 30, name: 'Beograd-Stari Grad' },
  { id: 31, name: 'Beograd-Surčin' },
  { id: 32, name: 'Beograd-Voždovac' },
  { id: 33, name: 'Beograd-Vračar' },
  { id: 34, name: 'Beograd-Zemun' },
  { id: 35, name: 'Beograd-Zvezdara' },
  { id: 36, name: 'Blace' },
  { id: 37, name: 'Bogatić' },
  { id: 38, name: 'Bojnik' },
  { id: 39, name: 'Boljevac' },
  { id: 40, name: 'Bor' },
  { id: 41, name: 'Bosilegrad' },
  { id: 42, name: 'Brus' },
  { id: 43, name: 'Bujanovac' },
  { id: 44, name: 'Čačak' },
  { id: 45, name: 'Čajetina' },
  { id: 46, name: 'Ćićevac' },
  { id: 47, name: 'Čoka' },
  { id: 48, name: 'Crna Trava' },
  { id: 49, name: 'Ćuprija' },
  { id: 50, name: 'Dečani' },
  { id: 51, name: 'Despotovac' },
  { id: 52, name: 'Dimitrovgrad' },
  { id: 53, name: 'Doljevac' },
  { id: 54, name: 'Gadžin Han' },
  { id: 55, name: 'Glogovac' },
  { id: 56, name: 'Gnjilane' },
  { id: 57, name: 'Golubac' },
  { id: 58, name: 'Gora' },
  { id: 59, name: 'Gornji Milanovac' },
  { id: 60, name: 'Inđija' },
  { id: 61, name: 'Irig' },
  { id: 62, name: 'Istok' },
  { id: 63, name: 'Ivanjica' },
  { id: 64, name: 'Jagodina' },
  { id: 65, name: 'Kačanik' },
  { id: 66, name: 'Kanjiža' },
  { id: 67, name: 'Kikinda' },
  { id: 68, name: 'Kladovo' },
  { id: 69, name: 'Klina' },
  { id: 70, name: 'Knić' },
  { id: 71, name: 'Knjaževac' },
  { id: 72, name: 'Koceljeva' },
  { id: 73, name: 'Kosjerić' },
  { id: 74, name: 'Kosovo Polje' },
  { id: 75, name: 'Kosovska Kamenica' },
  { id: 76, name: 'Kosovska Mitrovica' },
  { id: 77, name: 'Kovačica' },
  { id: 78, name: 'Kovin' },
  { id: 79, name: 'Kragujevac-grad' },
  { id: 80, name: 'Kraljevo' },
  { id: 81, name: 'Krupanj' },
  { id: 82, name: 'Kruševac' },
  { id: 83, name: 'Kučevo' },
  { id: 84, name: 'Kula' },
  { id: 85, name: 'Kuršumlija' },
  { id: 86, name: 'Lajkovac' },
  { id: 87, name: 'Lapovo' },
  { id: 88, name: 'Lebane' },
  { id: 89, name: 'Leposavić' },
  { id: 90, name: 'Leskovac' },
  { id: 91, name: 'Lipljan' },
  { id: 92, name: 'Ljig' },
  { id: 93, name: 'Ljubovija' },
  { id: 94, name: 'Loznica' },
  { id: 95, name: 'Lučani' },
  { id: 96, name: 'Majdanpek' },
  { id: 97, name: 'Mali Iđoš' },
  { id: 98, name: 'Mali Zvornik' },
  { id: 99, name: 'Malo Crniće' },
  { id: 100, name: 'Merošina' },
  { id: 101, name: 'Mionica' },
  { id: 102, name: 'Negotin' },
  { id: 103, name: 'Niš-Crveni Krst' },
  { id: 104, name: 'Niš-Grad' },
  { id: 105, name: 'Niš-Mediana' },
  { id: 106, name: 'Niš-Niška Banja' },
  { id: 107, name: 'Niš-Palilula' },
  { id: 108, name: 'Niš-Pantelej' },
  { id: 109, name: 'Nova Crnja' },
  { id: 110, name: 'Nova Varoš' },
  { id: 111, name: 'Novi Bečej' },
  { id: 112, name: 'Novi Kneževac' },
  { id: 113, name: 'Novi Pazar' },
  { id: 114, name: 'Novi Sad-grad' },
  { id: 115, name: 'Novo Brdo' },
  { id: 116, name: 'Obilić' },
  { id: 117, name: 'Odžaci' },
  { id: 118, name: 'Opovo' },
  { id: 119, name: 'Orahovac' },
  { id: 120, name: 'Osečina' },
  { id: 121, name: 'Pančevo' },
  { id: 122, name: 'Paraćin' },
  { id: 123, name: 'Peć' },
  { id: 124, name: 'Pećinci' },
  { id: 125, name: 'Petrovac' },
  { id: 126, name: 'Pirot' },
  { id: 127, name: 'Plandište' },
  { id: 128, name: 'Podujevo' },
  { id: 129, name: 'Požarevac' },
  { id: 130, name: 'Požega' },
  { id: 131, name: 'Preševo' },
  { id: 132, name: 'Priboj' },
  { id: 133, name: 'Prijepolje' },
  { id: 134, name: 'Priština' },
  { id: 135, name: 'Prokuplje' },
  { id: 136, name: 'Rača' },
  { id: 137, name: 'Raška' },
  { id: 138, name: 'Ražanj' },
  { id: 139, name: 'Rekovac' },
  { id: 140, name: 'Ruma' },
  { id: 141, name: 'Senta' },
  { id: 142, name: 'Sivac' },
  { id: 143, name: 'Sjenica' },
  { id: 144, name: 'Smederevo' },
  { id: 145, name: 'Smederevska Palanka' },
  { id: 146, name: 'Sokobanja' },
  { id: 147, name: 'Sombor' },
  { id: 148, name: 'Srbica' },
  { id: 149, name: 'Srbobran' },
  { id: 150, name: 'Sremska Mitrovica' },
  { id: 151, name: 'Sremski Karlovci' },
  { id: 152, name: 'Stara Pazova' },
  { id: 153, name: 'Subotica' },
  { id: 154, name: 'Surdulica' },
  { id: 155, name: 'Svilajnac' },
  { id: 156, name: 'Šabac' },
  { id: 157, name: 'Šid' },
  { id: 158, name: 'Štimlje' },
  { id: 159, name: 'Štrpce' },
  { id: 160, name: 'Temerin' },
  { id: 161, name: 'Titel' },
  { id: 162, name: 'Topola' },
  { id: 163, name: 'Trgovište' },
  { id: 164, name: 'Tutin' },
  { id: 165, name: 'Ub' },
  { id: 166, name: 'Užice' },
  { id: 167, name: 'Valjevo' },
  { id: 168, name: 'Varvarin' },
  { id: 169, name: 'Velika Plana' },
  { id: 170, name: 'Veliko Gradište' },
  { id: 171, name: 'Vladičin Han' },
  { id: 172, name: 'Vladimirci' },
  { id: 173, name: 'Vlasotince' },
  { id: 174, name: 'Vranje' },
  { id: 175, name: 'Vrnjačka Banja' },
  { id: 176, name: 'Vučitrn' },
  { id: 177, name: 'Žabalj' },
  { id: 178, name: 'Žabari' },
  { id: 179, name: 'Žagubica' },
  { id: 180, name: 'Žitište' },
  { id: 181, name: 'Žitorađa' },
  { id: 182, name: 'Zaječar' },
  { id: 183, name: 'Zrenjanin' },
];

export default prebivaliste_list;
