const zemlje_list = [
  { id: 1, name: 'Srbija' },
  { id: 2, name: 'Albanija' },
  { id: 3, name: 'Andora' },
  { id: 4, name: 'Austrija' },
  { id: 5, name: 'Azerbejdžan' },
  { id: 6, name: 'Belgija' },
  { id: 7, name: 'Belorusija' },
  { id: 8, name: 'Bosna i Hercegovina' },
  { id: 9, name: 'Bugarska' },
  { id: 10, name: 'Češka Republika' },
  { id: 11, name: 'Crna Gora' },
  { id: 12, name: 'Danska' },
  { id: 13, name: 'Estonija' },
  { id: 14, name: 'Finska' },
  { id: 15, name: 'Francuska' },
  { id: 16, name: 'Grčka' },
  { id: 17, name: 'Gruzija' },
  { id: 18, name: 'Holandija' },
  { id: 19, name: 'Hrvatska' },
  { id: 20, name: 'Irska' },
  { id: 21, name: 'Island' },
  { id: 22, name: 'Italija' },
  { id: 23, name: 'Jermenija' },
  { id: 24, name: 'Kazahstan' },
  { id: 25, name: 'Kipar' },
  { id: 26, name: 'Letonija' },
  { id: 27, name: 'Lihtenštajn' },
  { id: 28, name: 'Litvanija' },
  { id: 29, name: 'Luksemburg' },
  { id: 30, name: 'Mađarska' },
  { id: 31, name: 'Makedonija' },
  { id: 32, name: 'Malta' },
  { id: 33, name: 'Moldavija' },
  { id: 34, name: 'Monako' },
  { id: 35, name: 'Nemačka' },
  { id: 36, name: 'Norveška' },
  { id: 37, name: 'Poljska' },
  { id: 38, name: 'Portugalija' },
  { id: 39, name: 'Rumunija' },
  { id: 40, name: 'Rusija' },
  { id: 41, name: 'San Marino' },
  { id: 42, name: 'Slovačka' },
  { id: 43, name: 'Slovenija' },
  { id: 44, name: 'Španija' },
  { id: 45, name: 'Švajcarska' },
  { id: 46, name: 'Švedska' },
  { id: 47, name: 'Turska' },
  { id: 48, name: 'Ukrajina' },
  { id: 49, name: 'Vatikan' },
  { id: 50, name: 'Velika Britanija' },
];

export default zemlje_list;
